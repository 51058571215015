import React from 'react';
import isEqual from 'lodash/isEqual';
import { PENDING, SUCCESS, ERROR } from 'shared/constants/loadStateType';
import ScreenLoadPending from 'client/components/elements/screenLoadPending/ScreenLoadPending';
import Panel from 'react-bootstrap/lib/Panel';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import { getTotalizedValues } from 'client/utils/cartUtils';
import { getLocalizedString } from 'localization/localizer';
import { PanelHeading } from 'client/components/screens/checkoutScreen/accordionContent/AccordionContent';
import { DeliveryDetailsPanel } from 'client/components/screens/checkoutScreen/accordionContent/DeliveryDetailsPanel';
import { OrderReviewPanel } from 'client/components/screens/checkoutScreen/accordionContent/OrderReviewPanel';
import ProgressCircle from 'client/components/elements/progressCircle/ProgressCircle';
import {
  TotalizerSectionForCart,
  TotalizerSectionForQuotation,
  Continue2PaymentButton,
  isShowDeliveryOptionsLabel,
  eligibleProductCountForDeliveryOption
} from 'client/components/screens/orderReviewScreen/OrderReviewScreen';
import {
  DELIVERY_ADDRESS_ACCORDION_PANEL_ID,
  PAYMENT_ACCORDION_PANEL_ID,
  ORDER_REVIEW_ACCORDION_PANEL_ID,
  CHECKOUT_SELECTOR
} from 'shared/constants/singlePageCheckout';
import { PAY_ON_ACCOUNT } from 'shared/constants/braintree';
import PaymentMethod from 'client/components/elements/paymentMethod/PaymentMethod/paymentMethod';
import MaskCallDialog from 'client/components/dialogs/maskCallDialog';
import { getCheckoutProductCountAbTestThreshold, getVariant } from 'client/utils/unleashUtils';
import { BEFORE_YOUGO_PRODUCT_COUNT_RECOMMENDATIONS_TOGGLE, HIDE } from 'shared/constants/abTesting';
import { ALL_PRODUCTS_NOT_DELIVERABLE } from 'shared/constants/common';
import ConnectedUnleashWrapper from 'client/components/elements/unleashABTest/ConnectedUnleashWrapper';
import ScheduledOrdersPaymentDialog from 'client/components/dialogs/scheduledOrdersPaymentDialog/ScheduledOrdersPaymentDialog';
import { getToastMessage } from 'client/components/elements/toastWrapperComponent/toastWrapperComponent';
import { AgeRestrictedRemoveMessage } from 'client/components/elements/AgeRestrictedRemoveMessage/AgeRestrictedRemoveMessage';
import { BUSINESS_ADDRESS_APPROVED, BUSINESS_ADDRESS_NEW, LEGACY_BUSINESS_ADDRESS } from 'shared/constants/businessAddressStatus';

if (process.browser) {
  require('./Checkout.scss');
}
const TOASTER_DELAY = 4000;

const csDialogContentByVariant = (variant) => getLocalizedString({ mask: 'singlePageCheckout.cs.maskCallDialogBody', unmask: 'singlePageCheckout.cs.unmaskCallDialogBody' }[variant]);

export class Checkout extends React.Component {
  constructor (props) {
    super(props);
    this.deliveryPanel = React.createRef();
    this.paymentPanel = React.createRef();
    this.orderReviewPanel = React.createRef();
    this.confirmPaymentDetailsButton = React.createRef();
    this.scrollToTop = React.createRef();
  }

  shouldComponentUpdate (nextProps) {
    return !isEqual(this.props, nextProps);
  }

  componentWillReceiveProps (nextProps) { // eslint-disable-line react/no-deprecated
    if (nextProps.scrollToTop) {
      const element = document.getElementById('CheckoutPage');
      this.handleScroll(element);
    }
    if (nextProps.scrollToPaymentPanel) {
      const listOfErrorFields = document.querySelectorAll('.AddNewCardForm_inValidField, .has-error');
      if (listOfErrorFields) {
        const [firstErrorElement] = listOfErrorFields;
        this.handleScroll(firstErrorElement);
      }
    }
  }

  componentDidMount () {
    const params = new URLSearchParams(location.search);
    if (params.get(ALL_PRODUCTS_NOT_DELIVERABLE)) {
      this.props.toastInfo(getToastMessage(getLocalizedString('not.Allproduct.inStock')), 'top-right', TOASTER_DELAY);
      params.delete(ALL_PRODUCTS_NOT_DELIVERABLE);
      history.replaceState({}, '', `${location.pathname}?${params}${location.hash}`);
    }
  }

  handleScroll = (element) => {
    setTimeout(() => {
      element && element.scrollIntoView({ behavior: 'smooth' }) && element.focus({ preventScroll: false });
      this.props.resetAllScrolls();
    }, 800);
  }

  getCheckoutTitle = (firstName, checkoutSteps) => {
    return (
      <h1 className="CheckoutPage_header_checkoutTitle">
        {
          getLocalizedString('singlePageCheckout.checkout.title')
            .replace('{userFirstName}', firstName)
            .replace('{noOfSteps}', checkoutSteps)
        }
      </h1>
    );
  };

  accordionPanel = (props) => {
    const { panelId, panelBody } = props;
    return (
      <Panel eventKey={ panelId } key={ panelId }>
        <PanelHeading selector={ CHECKOUT_SELECTOR } { ...props } />
        <Panel.Body collapsible>
          { panelBody }
        </Panel.Body>
      </Panel>
    );
  };

  getProgressCircle = ({ loadingDefaultCard }) => {
    return (
      <React.Fragment>
        <ProgressCircle />
        <span className="Checkout_progressCircle_Text"> { loadingDefaultCard ? '' : getLocalizedString('payment.label.pleaseWait') } </span>
      </React.Fragment>
    );
  }

  totalizerSectionForQuotation = (isFarFlungDelivery) => {
    const {
      quotationDetails,
      accountDetails,
      includeVat,
      togglePlaceOrderButton,
      paymentMethod,
      loyaltyTier,
      addressId,
      addressList
    } = this.props;

    const hasAgeRestrictedProducts = quotationDetails.get('orderLines').some(orderLine => orderLine.get('ageRestricted'));
    const tradeAccount = accountDetails.get('tradeAccount');
    const isTradeAccount = tradeAccount != null && Object.keys(tradeAccount).length > 0;
    const currentSelectedAddress = (addressList || []).find(address => address.get('addressId') === addressId);
    const isCurrentSelectedAddressAllowed = currentSelectedAddress && [BUSINESS_ADDRESS_APPROVED, BUSINESS_ADDRESS_NEW, ...LEGACY_BUSINESS_ADDRESS].includes(currentSelectedAddress.get('businessAddressApprovalStatus'));
    const canContinueToPayment = !hasAgeRestrictedProducts || (isTradeAccount && isCurrentSelectedAddressAllowed);

    const isPayOnAccount = paymentMethod === PAY_ON_ACCOUNT;
    return (
      <div className="col-md-3 CheckoutPage_quotation">
        <TotalizerSectionForQuotation
          quotationDetails={ quotationDetails }
          loyaltyTier={ loyaltyTier }
          cta={ togglePlaceOrderButton
            ? canContinueToPayment ? <Continue2PaymentButton { ...this.props } selector={ CHECKOUT_SELECTOR }
              payOnAccount={ isPayOnAccount }
              placeOrderBtnSelector={ CHECKOUT_SELECTOR }
              placeOrderBtnDatae2e={ CHECKOUT_SELECTOR } />
              : <AgeRestrictedRemoveMessage message={ isCurrentSelectedAddressAllowed ? undefined : 'change-address' } />
            : this.getProgressCircle() }
          includeVat={ includeVat }
          isSinglePageCheckout
          isFarFlungDelivery={ isFarFlungDelivery }
        />
      </div>
    );
  }

  totalizerSectionForCart = (totalizedValues, showNonDeliverableOrderMessage, validNonDeliverableStatus) => {
    const {
      cart,
      includeVat,
      eligibleForDeliveryOptions,
      onApplyDiscountCode,
      onApplyDiscountCodeToExistingCart,
      onRemoveDiscountCode,
      togglePlaceOrderButton,
      paymentMethod,
      braintree,
      addressId,
      addressList,
      accountDetails
    } = this.props;
    const isPayOnAccount = paymentMethod === PAY_ON_ACCOUNT;
    const braintreeCards = braintree && braintree.cards;

    const hasAgeRestrictedProducts = cart.get('orderLines').some(orderLine => orderLine.get('ageRestricted'));
    const tradeAccount = accountDetails.get('tradeAccount');
    const isTradeAccount = tradeAccount != null && Object.keys(tradeAccount).length > 0;
    const displayPlaceOrderBtn = braintreeCards && togglePlaceOrderButton;
    const currentSelectedAddress = (addressList || []).find(address => address.get('addressId') === addressId);
    const isCurrentSelectedAddressAllowed = currentSelectedAddress && [BUSINESS_ADDRESS_NEW, BUSINESS_ADDRESS_APPROVED, ...LEGACY_BUSINESS_ADDRESS].includes(currentSelectedAddress.get('businessAddressApprovalStatus'));
    const canContinueToPayment = !hasAgeRestrictedProducts || (isTradeAccount && isCurrentSelectedAddressAllowed);
    return (
      <div className="col-md-3 CheckoutPage_cartTotal">
        <TotalizerSectionForCart
          isSinglePageCheckout={ true }
          togglePlaceOrderButton={ togglePlaceOrderButton }
          openOrderReviewPanel={ this.openOrderReviewPanel }
          totalizedValues={ totalizedValues }
          cart={ cart }
          cta={ displayPlaceOrderBtn
            ? canContinueToPayment
              ? <Continue2PaymentButton { ...{ ...this.props,
                showNonDeliverableOrderMessage,
                validNonDeliverableStatus } } placeOrderBtnSelector={ CHECKOUT_SELECTOR } selector={ CHECKOUT_SELECTOR }
              payOnAccount={ isPayOnAccount }
              placeOrderBtnDatae2e={ CHECKOUT_SELECTOR } />
              : <AgeRestrictedRemoveMessage message={ isCurrentSelectedAddressAllowed ? undefined : 'change-address' } />
            : this.getProgressCircle({ loadingDefaultCard: !braintreeCards }) }
          includeVat={ includeVat }
          onApplyDiscountCode={ onApplyDiscountCode }
          onApplyDiscountCodeToExistingCart = { onApplyDiscountCodeToExistingCart }
          onRemoveDiscountCode={ onRemoveDiscountCode }
          eligibleForDeliveryOptions={ eligibleForDeliveryOptions }
          addressId = { addressId }
        />
      </div>
    );
  };

  openOrderReviewPanel = () => {
    const { setActivePanelId } = this.props;
    return setActivePanelId({ panelId: ORDER_REVIEW_ACCORDION_PANEL_ID });
  }

  showDeliveryOptionsAvailability = (setActivePanelId, productCountForDeliveryOption, showDeliveryOptionsLabel) => {
    return !!productCountForDeliveryOption && showDeliveryOptionsLabel
      ? <span className="CheckoutPage_header_deliveryOptionTitle" onClick={ () => setActivePanelId({ panelId: ORDER_REVIEW_ACCORDION_PANEL_ID }) }>
        <strong className="CheckoutPage_header_deliveryOptionTitle-highlight">{ getLocalizedString('checkoutBreadcrumbs.label.optionsAvailable') }</strong>
        { getLocalizedString('singlePageCheckout.deliveryOptionOrderReview') }
      </span>
      : null;
  };

  render () {
    const {
      loadState,
      accountDetails,
      cart,
      activePanelId,
      setActivePanelId,
      fromQuotationId,
      quotationDetails,
      isOrderSubmitting,
      maskCallDialogVariant,
      unleashToggles,
      showNonDeliverableOrderMessage,
      validNonDeliverableStatus,
      showFarFlungDelvMessage
    } = this.props;
    let content;
    const loadStateType = loadState ? loadState.getIn(['type']) : PENDING;

    const totalizedValues = getTotalizedValues(cart);
    const showDeliveryOptionsLabel = isShowDeliveryOptionsLabel(cart);
    const productCountForDeliveryOption = eligibleProductCountForDeliveryOption(fromQuotationId, cart, quotationDetails);

    const showRecommendationCountVariant = getVariant(unleashToggles, BEFORE_YOUGO_PRODUCT_COUNT_RECOMMENDATIONS_TOGGLE, '');
    const unleashCountThreshold = getCheckoutProductCountAbTestThreshold(unleashToggles, HIDE);
    const cartOrderLines = cart.getIn(['orderLines']);
    const notTriggeredBeforeYouGoCountEvent = cartOrderLines.size >= unleashCountThreshold && showRecommendationCountVariant === HIDE;

    const accordionList = [
      {
        panelId: DELIVERY_ADDRESS_ACCORDION_PANEL_ID,
        name: getLocalizedString('singlePageCheckout.panel.deliveryDetailsPanelName'),
        panelReference: 'deliveryPanel',
        body: <DeliveryDetailsPanel { ...{ ...this.props, validNonDeliverableStatus, showFarFlungDelvMessage } } />
      },
      {
        panelId: PAYMENT_ACCORDION_PANEL_ID,
        name: getLocalizedString('singlePageCheckout.panel.paymentPanelName'),
        panelReference: 'paymentPanel',
        body: <PaymentMethod selector={ CHECKOUT_SELECTOR } location={ this.props.location } { ...this.props } />
      },
      {
        panelId: ORDER_REVIEW_ACCORDION_PANEL_ID,
        name: getLocalizedString('singlePageCheckout.panel.orderReviewPanelName'),
        panelReference: 'orderReviewPanel',
        body: <OrderReviewPanel selector={ CHECKOUT_SELECTOR } { ...this.props } totalizedValues={ totalizedValues } />
      }
    ];

    const getCheckoutLayout = () => {
      const deliveryAccordion = accordionList.find(accordion => accordion.panelId === DELIVERY_ADDRESS_ACCORDION_PANEL_ID)?.body || {};
      const deliveryZone = deliveryAccordion.props.deliveryAddress?.get('parcelForceZone');
      return (<React.Fragment>
        <MaskCallDialog title={ getLocalizedString(`singlePageCheckout.cs.maskCallDialogTitle`) } body={ csDialogContentByVariant(maskCallDialogVariant) } />
        <ScheduledOrdersPaymentDialog
          fromQuotationId={ fromQuotationId }
          validNonDeliverableStatus={ validNonDeliverableStatus }
        />
        <div className="CheckoutPage" id="CheckoutPage">
          <div className="CheckoutPage_header">
            {
              this.getCheckoutTitle(accountDetails.getIn(['firstName']), accordionList.length)
            }
            {
              this.showDeliveryOptionsAvailability(setActivePanelId, productCountForDeliveryOption, showDeliveryOptionsLabel)
            }
          </div>
        </div>
        <div className="CheckoutPage_body" ref={ this.accordionSection }>
          <div className="col-md-9">
            <PanelGroup accordion
              activeKey={ activePanelId }>
              {
                accordionList.map(accordionDetails => {
                  return (
                    <div key={ accordionDetails.panelId }>
                      <span id={ accordionDetails.panelReference } ref={ accordionDetails.panelReference } />
                      {
                        this.accordionPanel({
                          panelId: accordionDetails.panelId,
                          panelName: accordionDetails.name,
                          panelBody: accordionDetails.body,
                          ...this.props
                        })
                      }
                    </div>
                  );
                }

                )
              }
            </PanelGroup>
          </div>
          {
            fromQuotationId && quotationDetails
              ? this.totalizerSectionForQuotation(deliveryZone !== 1)
              : this.totalizerSectionForCart(totalizedValues, showNonDeliverableOrderMessage, validNonDeliverableStatus)
          }
        </div>
      </React.Fragment>);
    };
    switch (loadStateType) {
      case PENDING:
        content = <ScreenLoadPending />;
        break;
      case SUCCESS:
        content = getCheckoutLayout();
        break;
      case ERROR:
        content = <ScreenLoadPending location={ location } />;
        break;
    }

    return (
      <React.Fragment>
        { isOrderSubmitting ? <div className="CheckoutPage_pageDisabled" /> : null }
        { content }
        {
          notTriggeredBeforeYouGoCountEvent
            ? <ConnectedUnleashWrapper flag = { BEFORE_YOUGO_PRODUCT_COUNT_RECOMMENDATIONS_TOGGLE } />
            : null
        }
      </React.Fragment>
    );
  }
}
