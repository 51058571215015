import { setUnleashToggles, setUnleashContext } from 'client/actions/unleashActions';
import fetch from 'node-fetch';
import { UnleashClient, InMemoryStorageProvider } from '@graingerglobalonline/curly-eureka';

export const getContext = ({ unleashUserId, zoroUserId, isB2B, browserName, browserVersion, browserType, isSupported, isMobile, isTablet, isLoggedIn }) => {
  return { userId: unleashUserId, zoro_user_id: zoroUserId, browserName, browserVersion, browserType, isSupported, isMobile, isTablet, isB2B, isLoggedIn };
};

export const setInitialUnleashToggles = ({ unleashProxyConfig, unleashUserId, zoroUserId, deviceInfo, store, isLoggedIn }) => {
  try {
    const unleash = new UnleashClient({
      ...unleashSsrConfig,
      ...unleashProxyConfig.toJS()
    });
    unleash.updateContext(getContext({ unleashUserId, zoroUserId, ...deviceInfo, isLoggedIn }));
    unleash.start().then(() => {
      const toggles = unleash.getAllToggles();
      store.dispatch(setUnleashToggles(toggles));
      const context = unleash.getContext();
      store.dispatch(setUnleashContext(context));
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Error while setting unleash toggles, Error ${err}`);
  }
};

export const unleashSsrConfig = {
  fetch,
  storageProvider: new InMemoryStorageProvider(),
  disableMetrics: true,
  disableRefresh: true
};
